<template>
  <v-app>
    <v-app-bar
      class="pa-2"
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-toolbar-title>Jake McKay</v-toolbar-title>
      </div>

      <v-spacer></v-spacer>

        <v-select
        label="Select a document"
        v-model="src"
        :items="pdfList"
        item-text="name"
        item-value="path"
        style="width: 30em">
        </v-select>

        <v-text-field
          v-model="page"
          :label="' Page' + page + ' of ' + numPages"
          style="width: 5em"
        >
          <v-icon
            @click="page++"
            slot="append"
            color="red"
          >
            mdi-plus
          </v-icon>
          <v-icon
            @click="page--"
            slot="prepend"
            color="green"
          >
            mdi-minus
          </v-icon>
           /{{numPages}}
        </v-text-field>
        <v-spacer></v-spacer>
        <v-btn @click="rotate += 90">&#x27F3;</v-btn>
        <v-btn @click="rotate -= 90">&#x27F2;</v-btn>
        <v-btn @click="$refs.pdf.print()">print</v-btn>
    </v-app-bar>

    <v-container justify="center">
      <v-row>
        <v-col sm="12" md="10" offset-md="1" lg="8" offset-lg="2">
            <div v-if="loadedRatio > 0 && loadedRatio < 1" style="background-color: green; color: white; text-align: center" :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%</div>
            <pdf v-if="show" ref="pdf" style="border: 1px solid red" :src="src" :page="page" :rotate="rotate" @password="password" @progress="loadedRatio = $event" @error="error" @num-pages="numPages = $event" @link-clicked="page = $event"></pdf>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import pdf from 'vue-pdf'
 
export default {
    components: {
        pdf: pdf
    },
    data () {
        return {
            show: true,
            pdfList: [
                {name:'',path:''},
                {name:'Immersive labs cyberserurity',path:'CPE-Report.pdf'},
                {name:'Linkedin Learning',path:'CertificateOfCompletion_LinkedinLearning_combined.pdf'},
            ],
            src:'',
            pageTitle: 'LNTrust',
            loadedRatio: 0,
            page: 1,
            numPages: 0,
            rotate: 0,
        }
    },
    methods: {
        password: function(updatePassword) {
 
            updatePassword(prompt('password is "test"'));
        },
        error: function(err) {
 
            console.log(err);
        }
    }
}
</script>
